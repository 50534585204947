import React, { Component } from 'react';
import { mat4, vec3, quat } from 'gl-matrix';
import Tree from 'rc-tree';

import api from "../../../api";
import delay from "delay";

import { Viewer } from './hangar/hangar';

import Loading from "react-loading";

import 'rc-tree/assets/index.css';
import '../../../styles/3DView.css'

// Lower is faster
const rotateSpeed = 4;

// Bigger is faster
const zoomSpeed = 1.02;

function switcherIcon(obj) {
    if (obj.isLeaf) {
        return "";
    }

    if (obj.expanded) {
        return (
            <i className="rc-tree-switcher-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 24 24"><path d="M12 21l-12-18h24z" /></svg>
            </i>

        );
    }
    return (
        <i className="rc-tree-switcher-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 24 24"><path d="M21 12l-18 12v-24z" /></svg>
        </i>
    );
}


function visibleIcon(obj) {
    if (obj.checked) {
        return (
            <i className="rc-tree-visible-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="#4d4d4d" width="15" height="15" viewBox="0 0 24 24"><path d="M19.604 2.562l-3.346 3.137c-1.27-.428-2.686-.699-4.243-.699-7.569 0-12.015 6.551-12.015 6.551s1.928 2.951 5.146 5.138l-2.911 2.909 1.414 1.414 17.37-17.035-1.415-1.415zm-6.016 5.779c-3.288-1.453-6.681 1.908-5.265 5.206l-1.726 1.707c-1.814-1.16-3.225-2.65-4.06-3.66 1.493-1.648 4.817-4.594 9.478-4.594.927 0 1.796.119 2.61.315l-1.037 1.026zm-2.883 7.431l5.09-4.993c1.017 3.111-2.003 6.067-5.09 4.993zm13.295-4.221s-4.252 7.449-11.985 7.449c-1.379 0-2.662-.291-3.851-.737l1.614-1.583c.715.193 1.458.32 2.237.32 4.791 0 8.104-3.527 9.504-5.364-.729-.822-1.956-1.99-3.587-2.952l1.489-1.46c2.982 1.9 4.579 4.327 4.579 4.327z" /></svg>
            </i>
        );
    }

    if (obj.halfChecked) {
        return (
            <i className="rc-tree-visible-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="#4d4d4d" width="15" height="15" viewBox="0 0 24 24"><path d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 3c-2.209 0-4 1.792-4 4 0 2.209 1.791 4 4 4s4-1.791 4-4c0-2.208-1.791-4-4-4z" /></svg>
            </i>
        );
    }

    return (
        <i className="rc-tree-visible-icon">
            <svg xmlns="http://www.w3.org/2000/svg" fill="#838484" width="15" height="15" viewBox="0 0 24 24"><path d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 3c-2.209 0-4 1.792-4 4 0 2.209 1.791 4 4 4s4-1.791 4-4c0-2.208-1.791-4-4-4z" /></svg>
        </i>
    );
}


class ObjectTree extends Component {
    static defaultProps = {
        keys: ['empty.object'],
    };

    createTree = (objects) => {
        if (!objects) {
            return [];
        }

        let map = new Map();
        let topKeys = new Set();
        for (let i = 0; i < objects.length; ++i) {

            let res = objects[i].split(".");
            let splits = 0;

            for (let x = 0; x < splits; ++x) {
                res[res.length - 2] += "." + res.pop();
            }

            let key = res[0];
            topKeys.add(res[0]);
            for (let x = 1; x < res.length; ++x) {
                if (map.has(key)) {
                    map.get(key).add(key + "." + res[x] + " " + res[x]);
                } else {
                    map.set(key, new Set([key + "." + res[x] + " " + res[x]]));
                }
                key += "." + res[x];
            }
        }

        const loop = function (dt) {
            let children = [];
            for (let v of dt) {
                let key = v.split(" ")[0], name = v.split(" ")[1];
                if (map.has(key)) {
                    let c = loop(map.get(key));
                    children.push({ key: key, title: name, children: c });
                } else {
                    children.push({ key: key, title: name });
                }
            }
            return children;
        };

        let data = [];
        for (let key of topKeys) {
            if (map.has(key)) {
                let children = loop(map.get(key));
                data.push({ key: key, title: key, children: children });
            } else {
                data.push({ key: key, title: key });
            }
        }

        return data;
    }

    onCheck = (checkedKeys, info) => {
        this.props.onCheck(checkedKeys);
    };

    render() {
        if (!this.props.objects) {
            return null;
        }

        return (
            <Tree
                className="objectList"
                checkable
                selectable={false}
                onExpand={this.onExpand}
                defaultCheckedKeys={[]}
                onCheck={this.onCheck}
                treeData={this.createTree(this.props.objects)}
                icon={visibleIcon}
                switcherIcon={switcherIcon}
            />
        );
    }

}




class LabelList extends Component {
    constructor(props) {
        super(props);

        this.canvas = React.createRef();
        this.labels = React.createRef();

        this.state = { visible: true };
    }

    componentDidUpdate = () => {
        this.draw();
    }

    draw = () => {
        let canvas = this.canvas.current;
        if (!canvas || !this.props.camera) {
            return;
        }
        let ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        if (!this.state.visible) {
            return;
        }

        var point = vec3.create(), transformMat = mat4.create(), projector = mat4.create();

        mat4.perspective(projector, this.props.camera.fov,
            canvas.width / canvas.height, 0.1, 1000.0);
        mat4.multiply(transformMat, projector, this.props.transformer);

        for (let i = 0; i < this.props.labels.length; ++i) {
            let label = this.props.labels[i];
            vec3.transformMat4(point, label.loc, transformMat);
            let descEl = document.querySelector("#label_" + i);

            if (-1 < point[0] && point[0] < 1 &&
                -1 < point[1] && point[1] < 1 &&
                -1 < point[2] && point[2] < 1) {
                let y1 = canvas.height * (-point[1] + 1) / 2;
                descEl.style.order = Math.floor(y1 * 1000).toString();
            } else {
                descEl.style.order = (1e12).toString();
            }
        }

        for (let i = 0; i < this.props.labels.length; ++i) {
            let label = this.props.labels[i];
            vec3.transformMat4(point, label.loc, transformMat);

            if (-1 < point[0] && point[0] < 1 &&
                -1 < point[1] && point[1] < 1 &&
                -1 < point[2] && point[2] < 1) {
                let x1 = canvas.width * (point[0] + 1) / 2;
                let y1 = canvas.height * (-point[1] + 1) / 2;

                let descEl = document.querySelector("#label_" + i);
                let labelContainer = this.labels.current;
                let x2 = descEl.offsetLeft + labelContainer.offsetLeft;
                let y2 = descEl.offsetTop + labelContainer.offsetTop + descEl.offsetHeight / 2;

                ctx.strokeStyle = "rgba(70, 70, 70, 200)";
                ctx.beginPath();
                ctx.moveTo(x1, y1);
                ctx.lineTo(x2, y2);
                ctx.stroke();
            }
        }
    }

    labelsToggler = () => {
        this.setState(() => ({ visible: !this.state.visible }))
    }

    drawLabel = (item, key) => {
        return (
            <button id={"label_" + key} key={key} className="labelItem" onClick = {() => {
                this.props.camera.poi = [...item.loc]
                this.props.camera.computeMatrix()
            }}>
                <p>{item.text}</p>
            </button>
        )
    }

    render() {
        return (
            <div className="labels-component" zindex="10">
                <canvas id="label-canvas" ref={this.canvas} height={this.props.height} width={this.props.width}></canvas>
                <div className="labels" ref={this.labels}>
                    <button type="button" onClick={() => { this.labelsToggler() }} style={{ pointerEvents: "auto" }}>
                        <span style={{verticalAlign: "middle"}}>{switcherIcon({isLeaf: false, expanded: this.state.visible})}</span>{this.state.visible ? "Hide" : "Show"} Labels</button>
                    {(this.state.visible) ? this.props.labels.map(this.drawLabel) : ""}
                </div>
            </div>
        );
    }
}


class ModelView extends Component {
    constructor(props) {
        super(props);
        this.state = { checkedItems: new Map(), objects: [], height: 500, width: 750, transformer: null, loaded:false, fileMap: {}, showInstructions: false };
        this.objects = [];
        this.scene = null;
        this.center = null;

        this.canvas = React.createRef();
        this.viewCubeCanvas = React.createRef();

        this.handleChange = this.handleChange.bind(this);

    }

    getFiles = async () => {

        let fileNames = ["model.ac", "injuries.ac", "annular_rear_color.jpg"]
        if (this.props.studyType === "Cervical") {
            fileNames = [...fileNames, ...["annulus.jpg", "bones.jpg", "cord.jpg", "normal.annulus.jpg", "normal.bones.jpg", "normal.cord.jpg", "normal.nucleus.jpg", "nucleus.jpg", "annulus_tear_anterior_left.png", "annulus_tear_posterior_right.png", "normal.annulus_tear_posterior_right.png", "normal.annulus_tear_anterior_left.png", "annulus_tear_anterior_right.png", "annulus_tear_posterior_left.png", "normal.annulus_tear_posterior_left.png", "normal.annulus_tear_anterior_right.png", "normal.annulus_tear_right.png", "normal.annulus_tear_left.png", "annulus_tear_right.png", "annulus_tear_left.png", "normal.annulus_tear_anterior_central.png", "normal.annulus_tear_posterior_central.png", "annulus_tear_anterior_central.png", "annulus_tear_posterior_central.png", "normal.annulus_tear_posterior_anterior.png", "annulus_tear_posterior_anterior.png", "normal.annulus_tear_posterior_bilateral.png", "annulus_tear_posterior_bilateral.png"]]
        }

        else if (this.props.studyType === "Lumbar") {
            fileNames = [...fileNames, ...["annulus.png", "bones.png", "cord.png", "normal.annulus.png", "normal.bones.png", "normal.cord.png", "normal.nucleus.jpg","nucleus.jpg", "sacrum.jpg", "normal.sacrum.jpg", "annulus_tear_right.png", "annulus_tear_left.png", "normal.annulus_tear_right.png", "normal.annulus_tear_left.png", "normal.annulus_tear_anterior_left.png", "normal.annulus_tear_anterior_central.png", "annulus_tear_anterior_left.png", "annulus_tear_anterior_central.png", "normal.annulus_tear_posterior_right.png", "annulus_tear_posterior_right.png", "normal.annulus_tear_posterior_left.png", "annulus_tear_posterior_left.png", "normal.annulus_tear_posterior_central.png", "annulus_tear_posterior_central.png", "annulus_tear_anterior_right.png", "normal.annulus_tear_anterior_right.png", "normal.annulus_tear_posterior_bilateral.png", "annulus_tear_posterior_bilateral.png"]]
        }

        else if (this.props.studyType === "Thoracic") {
            fileNames = [...fileNames, ...["annulus.jpg", "bones.jpg", "cord.jpg", "normal.annulus.jpg", "normal.bones.jpg", "normal.cord.jpg", "normal.nucleus.jpg", "nucleus.jpg", "annulus_tear_anterior_left.png", "annulus_tear_posterior_right.png", "normal.annulus_tear_posterior_right.png", "normal.annulus_tear_anterior_left.png", "annulus_tear_anterior_right.png", "annulus_tear_posterior_left.png", "normal.annulus_tear_posterior_left.png", "normal.annulus_tear_anterior_right.png", "normal.annulus_tear_right.png", "normal.annulus_tear_left.png", "annulus_tear_right.png", "annulus_tear_left.png", "normal.annulus_tear_anterior_central.png", "normal.annulus_tear_posterior_central.png", "annulus_tear_anterior_central.png", "annulus_tear_posterior_central.png", "normal.annulus_tear_posterior_bilateral.png", "annulus_tear_posterior_bilateral.png"]]
        }

        else if (this.props.studyType === "Brain") {
            fileNames = [...fileNames, ...["basal_nuclei.jpg", "cerebrum.jpg", "meanders.jpg", "nerves.jpg", "stem_brain.jpg", "thalamus.jpg", "ventricles.jpg", "normal.basal_nuclei.jpg", "normal.cerebrum.jpg", "normal.meanders.jpg", "normal.nerves.jpg", "normal.stem_brain.jpg", "normal.thalamus.jpg", "normal.ventricles.jpg"]]
        }

        else if (this.props.studyType === "LeftKnee" || this.props.studyType === "RightKnee") {
            fileNames = [...fileNames, ...["connective_Bursa.jpg", "connective_hip_knee.jpg", "connective_knee2.jpg", "muscles_low_leg.jpg", "muscles_up_legA.jpg", "muscles_up_legB.jpg", "skeleton_legs.jpg", "normal.connective_Bursa.jpg", "normal.connective_hip_knee.jpg", "normal.connective_knee2.jpg", "normal.muscles_low_leg.jpg", "normal.muscles_up_legA.jpg", "normal.muscles_up_legB.jpg", "normal.skeleton_legs.jpg"]]
        }

        else if (this.props.studyType === "LeftShoulder" || this.props.studyType === "RightShoulder") {
            fileNames = [...fileNames, ...["bursa.jpg", "ligaments.jpg", "muscles.jpg", "skeleton.jpg", "normal.bursa.jpg", "normal.ligaments.jpg", "normal.muscles.jpg", "normal.skeleton.jpg"]]
        }

        else if (this.props.studyType === "LeftAnkle" || this.props.studyType === "RightAnkle" || this.props.studyType === "RightFoot" || this.props.studyType === "LeftFoot") {
            fileNames = [...fileNames, ...["skeletonlegs.jpg", "skeletonfoot.jpg", "muscleslegs.jpg", "musclesfeet.jpg", "ligaments.jpg", "normal.skeletonlegs.jpg", "normal.skeletonfoot.jpg", "normal.muscleslegs.jpg", "normal.musclesfeet.jpg", "normal.ligaments.jpg"]]
        }

        else if (this.props.studyType === "LeftHip" || this.props.studyType === "RightHip" || this.props.studyType === "Hip") {
            fileNames = [...fileNames, ...["connective_hip_knee.png", "normal.connective_hip_knee.png", "ligaments.png", "normal.ligaments.png", "muscles_up_legA.jpg", "muscles_up_legB.jpg", "muscles_up_legA.png", "normal.muscles_up_legA.png", "muscles_up_legB.jpg", "normal.muscles_up_legB.jpg", "muscles_low_leg.jpg", "normal.muscles_low_leg.jpg", "skeleton_legs_pelvis_modified_.png", "normal.skeleton_legs_pelvis_modified_.png", "gluteus.png", "normal.gluteus.png"]]
        }

        else if (this.props.studyType === "LeftHand" || this.props.studyType === "RightHand" || this.props.studyType === "LeftWrist" || this.props.studyType === "RightWrist") {
            fileNames = [...fileNames, ...["muscles.png", "normal.muscles.png", "normal.bones.png", "bones.png", "normal.connective.png", "connective.png"]]
        }

        else if (this.props.studyType === "LeftElbow" || this.props.studyType === "RightElbow") {
            fileNames = [...fileNames, ...["muscles.png", "normal.muscles.png", "bones.png", "normal.bones.png", "ligaments.jpg", "normal.ligaments.jpg"]]
        }        

        let downloadMap = {
            "emptyTexture.jpg": "/resources/emptyTexture.jpg",
            "normal.emptyTexture.jpg": "/resources/normal.emptyTexture.jpg",
        }

        let downloads = fileNames.map(async fileName => {

            // Grab the relevent project files
            return new Promise(async (resolve, reject) => {

                try {

                    let stream = this.props.accessKey === '' ? await api.post('downloadProjectFile', {
                        projectUID: this.props.projectID,
                        studyType: this.props.studyType,
                        fileName
                    }, true, { responseType: "blob", timeout: 60 * 1000 }) : await api.post('downloadProjectFileKey', {
                        caseNumber: this.props.case,
                        projectUID: this.props.projectID,
                        accessKey: this.props.accessKey,
                        studyType: this.props.studyType,
                        fileName
                    }, true, { responseType: "blob", timeout: 60 * 1000 });
    
                    let blob = new Blob(
                        [stream]
                    )
                    let blobURL = URL.createObjectURL(blob)
                    downloadMap[fileName] = blobURL
                    resolve(true)
                    
                } catch (error) {
                    
                    if (fileName.includes(".png") || fileName.includes(".jpg")) {

                        console.log("Download timed out for", fileName + ".", "Using default textures.")

                        if (fileName.includes("normal.")) {
                            downloadMap[fileName] = "/resources/normal.emptyTexture.jpg"
                        } else {
                            downloadMap[fileName] = "/resources/emptyTexture.jpg"
                        }
                        
                        resolve(true)

                    } else {

                        // If here, we actually can't load the model. TODO: Show visual feedback to the user
                        //  So they know they haven't downloaded everything. For now, just don't do anything.
                        console.log("Unable to Load all data")

                    }

                }

                
            });

        })

        await Promise.all(downloads)
        this.setState({fileMap: downloadMap})
        return downloadMap

    }

    componentDidMount = async () => {

        let modelPath = '/resources/ViewCube/' + ((this.props.patientSex === "F") ? "Female" : "Male") + '/';

        let fileMap = {
            'model.ac': modelPath + 'model.ac',
            'axes.jpg': modelPath + 'axes.jpg',
            'Eye.png': modelPath + 'Eye.png',
            'Mick_armslegs.jpg': modelPath + 'Mick_armslegs.jpg',
            'Mick_body.jpg': modelPath + 'Mick_body.jpg',
            'Mick_head.jpg': modelPath + 'Mick_head.jpg',
            'BeautyWoman_calvin-klein.jpg': modelPath + 'BeautyWoman_calvin-klein.jpg',
            'BeautyWoman_eye.jpg': modelPath + 'BeautyWoman_eye.jpg',
            'BeautyWoman_Lips_color.jpg': modelPath + 'BeautyWoman_Lips_color.jpg'
        }

        let viewCubeCanvas = this.viewCubeCanvas.current;
        this.viewerCube = new Viewer(viewCubeCanvas, true, false);

        this.viewerCube.show(
            modelPath + 'model.ac',
            {
                setup: null,
                fileMap,
                control: false,
                injuries: false,
                callback: () => {
                    // Set coronal view for viewerCube
                    this.viewerCube.renderer.camera.eye = [
                        this.viewerCube.renderer.camera.poi[0] + 1.4,
                        this.viewerCube.renderer.camera.poi[1],
                        this.viewerCube.renderer.camera.poi[2]
                    ];
                    this.viewerCube.renderer.camera.poi = [0, 0, 0];
                    this.viewerCube.renderer.camera.up = [0.0, 0.0, 1.0];
                    this.viewerCube.renderer.camera.computeMatrix();
                }
            });

        let canvas = this.canvas.current;
        // TODO: Set proper width and height through props
        this.viewer = new Viewer(canvas);
        this.setState(() => ({ height: window.innerHeight - 74, width: window.innerWidth, camera: this.viewer.renderer.camera }));
        fileMap = await this.getFiles();

        // Model has to be in same folder as textures (for finding them)
        this.viewer.show(
            fileMap["model.ac"],
            {
                setup: null,
                callback: () => {
                    // this.onViewerLoaded()
                    // TODO: Figure out why this doesn't load properly. Namely textures
                    this.viewer.addModel(
                        fileMap["injuries.ac"],
                        { 
                            callback: () => { 
                                this.onViewerLoaded()
                            },
                            fileMap: fileMap,
                            injuries: true,
                        });
                },
                onTick: () => {
                    let nTransformer = this.viewer.renderer.camera.transformer;
                    if (!this.state.transformer || !mat4.exactEquals(this.state.transformer, nTransformer)) {
                        this.updateViewerCube();
                        this.setState(() => ({ transformer: mat4.clone(nTransformer) }));
                    }
                },
                fileMap,
                labels: this.props.labels,
                control: true,
                injuries: false,
            });
        window.addEventListener('resize', this.onResize.bind(this));
    }

    async fitCamera() {

        let boundingBox = this.viewer.scenes[0].boundingBox;
        let bounds = {
            x: [boundingBox.xmin, boundingBox.xmax],
            y: [boundingBox.ymin, boundingBox.ymax],
            z: [boundingBox.zmin, boundingBox.zmax]
        }

        let maxBound = bounds.x[1] - bounds.x[0];
        if (bounds.y[1] - bounds.y[0] > maxBound) { maxBound = bounds.y[1] - bounds.y[0] }
        if (bounds.z[1] - bounds.z[0] > maxBound) { maxBound = bounds.z[1] - bounds.z[0] }

        let distance = maxBound / (2 * Math.atan(this.viewer.renderer.camera.fov / 180 * Math.PI / 2)) * 1.1

        // Now that we have the max bounds, lets determine where the camera needs to be to see everything.
        // Determine whether width or height is minimum and use that for fitting
        let dir = vec3.create()
        vec3.subtract(dir, this.viewer.renderer.camera.eye, this.viewer.renderer.camera.poi);
        vec3.normalize(dir, dir);
        vec3.scale(dir, dir, distance);

        this.viewer.renderer.camera.eye = vec3.add(dir, dir, this.viewer.renderer.camera.poi);
        this.viewer.renderer.camera.computeMatrix()

    }

    updateViewerCube() {
        let nTransformer = this.viewer.renderer.camera.transformer;
        let q = quat.create(), v = vec3.create(),
            cubeTransformer = this.viewerCube.renderer.camera.transformer;
        mat4.getRotation(q, nTransformer);
        mat4.getTranslation(v, cubeTransformer);
        mat4.fromRotationTranslation(cubeTransformer, q, v);
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.onResize.bind(this));
        for (const [_, value] of Object.entries(this.state.fileMap)) {
            URL.revokeObjectURL(value)
          }
    }

    onResize = (e) => {
        this.viewer.onResize(window.innerWidth, window.innerHeight - 74);
        this.setState(() => ({
            height: window.innerHeight - 74,
            width: window.innerWidth
        }));
    }

    onViewerLoaded = async () => {

        this.setState(() => ({ transformer: mat4.clone(this.viewer.renderer.camera.transformer) }));

        let objects = []
        let center = [0.0, 0.0, 0.0]
        let count = 0;
        for (let scene of this.viewer.scenes) {
            for (let group of scene.groups) {
                for (let i = 0; i < group.buffer.length; i+=11) {
                    center[0] = center[0] + group.buffer[i + 0]
                    center[1] = center[1] + group.buffer[i + 1]
                    center[2] = center[2] + group.buffer[i + 2]
                    count++
                }
            }
            objects.push(...scene.objects);
        }
        center[0] = center[0] / count
        center[1] = center[1] / count
        center[2] = center[2] / count
        
        this.center = center

        // Set coronal view
        this.viewer.renderer.camera.poi = [...this.center];
        this.viewer.renderer.camera.eye = [
            this.viewer.renderer.camera.poi[0] + .25,
            this.viewer.renderer.camera.poi[1],
            this.viewer.renderer.camera.poi[2]
        ];
        this.viewer.renderer.camera.up = [0.0, 0.0, 1.0];
        this.viewer.renderer.camera.computeMatrix();
        this.fitCamera()

        this.setState(() => ({ objects: objects, loaded: true }));
        this.updateViewerCube()
    }

    handleChange = (checked) => {
        for (let scene of this.viewer.scenes) {
            for (let i = 0; i < scene.materials.length; ++i) {
                scene.materials[i].transparency = Number(scene.materials[i].transparencyControl)
            }
        }

        for (let item of checked) {
            for (let scene of this.viewer.scenes) {
                if (scene.objectMaterials.has(item)) {
                    let materialIdx = scene.objectMaterials.get(item);
                    scene.materials[materialIdx].transparency = 1.0
                    break;
                }
            }
        }
    }

    render() {

        let centerX = this.state.width / 2;
        let centerY = this.state.height / 2;

        return (
            <div className="model-view">

                {!this.state.loaded && <div className="loadingIconContainer">
                    <Loading type="spin" color="rgba(230,155,30,1.0)" height={'120px'} width={'120px'} />
                </div>}

                {this.state.loaded && !this.state.showInstructions && <div
                    className="hiddenInstructions"
                    onClick={()=> this.setState({showInstructions: true})}
                >Click here for instructions.
                </div>}

                {this.state.loaded && this.state.showInstructions && <div
                    className="shownInstructions"
                    onClick={()=> this.setState({showInstructions: false})}
                >
                    <div className="controlDiv">
                        <div className="controlType"><p>Pan</p></div>
                        <div className="controlExplanation"><p>
                            Click and drag with the left mouse button, or touch and drag with two fingers.
                        </p></div>
                    </div>

                    <div className="controlDiv">
                        <div className="controlType"><p>Rotate</p></div>
                        <div className="controlExplanation"><p>Click and drag with the left mouse button while holding down the SHIFT key. Touch and drag with one finger.</p></div>
                    </div>

                    <div className="controlDiv">
                        <div className="controlType"><p>Zoom</p></div>
                        <div className="controlExplanation"><p>Scroll with your mouse wheel, or pinch with your fingers.</p></div>
                    </div>

                    <div className="controlDiv">
                        <div className="controlType"><p>Center</p></div>
                        <div className="controlExplanation"><p>Click on the rectangle button next to the zoom out button on the bottom-left.</p></div>
                    </div>

                    <div className="controlDiv">
                        <div className="controlType"><p>Show/<br></br>Hide</p></div>
                        <div className="controlExplanation"><p>Click on the eye icon to the left to show/hide anatomy. Click on the dropdown to see more options.</p></div>
                    </div>

                    <div className="controlDiv">
                        <div className="controlType"><p>Snap</p></div>
                        <div className="controlExplanation"><p>Click on a label to center view on that injury.</p></div>
                    </div>

                </div>}

                {this.state.loaded && <ObjectTree objects={this.state.objects} viewer={this.viewer} onCheck={this.handleChange} />}
                <canvas id="canvas" ref={this.canvas} height={this.state.height} width={this.state.width}></canvas>
                <canvas id="viewCubeCanvas" ref={this.viewCubeCanvas} height="175" width="175"></canvas>
                { this.props.labels && this.state.loaded ?
                    <LabelList height={this.state.height} width={this.state.width} camera={(this.viewer) ? this.viewer.renderer.camera : null}
                        transformer={this.state.transformer}
                        labels={this.props.labels} />
                    : "" }

                <div className="rotateContainer">
                    <button className="rotateButton rotateUp" onClick = {async () => {
                        if (!this.state.loaded) return;
                        for (let i = 0; i < 32; i++) {
                            await delay(20)
                            this.viewer.trackball.track(centerX, centerY, centerX, centerY + centerY / (rotateSpeed * 32))
                        }
                        
                    }}>
                        <img src="/resources/icons/rotate.svg" alt="rotate" className="rotateImage"/>
                    </button>

                    <button className="rotateButton rotateDown" onClick = {async () => {
                        if (!this.state.loaded) return;
                        for (let i = 0; i < 32; i++) {
                            await delay(20)
                            this.viewer.trackball.track(centerX, centerY, centerX, centerY - centerY / (rotateSpeed * 32))
                        }
                    }}>
                        <img src="/resources/icons/rotate.svg" alt="rotate" className="rotateImage"/>
                    </button>

                    <button className="rotateButton rotateRight" onClick = {async () => {
                        if (!this.state.loaded) return;
                        for (let i = 0; i < 32; i++) {
                            await delay(20)
                            this.viewer.trackball.track(centerX, centerY, centerX - centerX / (rotateSpeed * 32), centerY)
                        }
                        
                    }}>
                        <img src="/resources/icons/rotate.svg" alt="rotate" className="rotateImage"/>
                    </button>

                    <button className="rotateButton rotateLeft" onClick = {async () => {
                        if (!this.state.loaded) return;
                        for (let i = 0; i < 32; i++) {
                            await delay(20)
                            this.viewer.trackball.track(centerX, centerY, centerX + centerX / (rotateSpeed * 32), centerY)
                        }                       
                        
                    }}>
                        <img src="/resources/icons/rotate.svg" alt="rotate" className="rotateImage"/>
                    </button>
                </div>

                <div className="zoomContainer">
                    <button className="zoomButton" onClick={async () => {
                        let newLocation = window.location.href.slice(window.location.origin.length, window.location.href.length).split("/")
                        newLocation = newLocation.slice(0, newLocation.length - 1).join("/")
                        console.log(newLocation)
                        this.props.history.push(newLocation)
                    }}>
                        <img src="/resources/icons/left.png" alt="zoom" className="zoomSVG"/>
                    </button>

                    <button className="zoomButton" onClick={async () => {
                        if (!this.state.loaded) { return }
                        for (let i = 0; i < 32; i++) {
                            await delay(20)
                            this.viewer.renderer.camera.zoom(1/zoomSpeed)
                        }
                    }}>
                        <img src="/resources/icons/zoom.svg" alt="zoom" className="zoomSVG"/>
                    </button>
                    <button className="zoomButton" onClick={async () => {
                        if (!this.state.loaded) { return }
                        for (let i = 0; i < 32; i++) {
                            await delay(20)
                            this.viewer.renderer.camera.zoom(zoomSpeed)
                        }
                    }}>
                        <img src="/resources/icons/zoom-out.svg" alt="zoom" className="zoomSVG"/>
                    </button>
                    <button className="zoomButton" onClick={() => {
                        if (!this.state.loaded) { return }
                        this.viewer.renderer.camera.poi = [...this.center];
                        this.viewer.renderer.camera.computeMatrix();
                        this.fitCamera()
                    }}>
                        <img src="/resources/icons/center.svg" alt="zoom" className="zoomSVG"/>
                    </button>
                </div>

                <div className="viewButtonContainer">
                    <button className="viewButton"
                        onClick = {async () => {

                            this.viewer.renderer.camera.poi = [...this.center];
                            this.viewer.renderer.camera.eye = [
                                this.viewer.renderer.camera.poi[0],
                                this.viewer.renderer.camera.poi[1],
                                this.viewer.renderer.camera.poi[2] + .25
                            ];
                            this.viewer.renderer.camera.up = [1.0, 0.0, 0.0];
                            this.viewer.renderer.camera.computeMatrix();

                            this.fitCamera();
                            this.updateViewerCube();

                            this.setState(() => ({ transformer: mat4.clone(this.viewer.renderer.camera.transformer) }));
                        }}
                    >Axial</button>
                    <button className="viewButton"
                        onClick = {async () => {

                            this.viewer.renderer.camera.poi = [...this.center];
                            this.viewer.renderer.camera.eye = [
                                this.viewer.renderer.camera.poi[0],
                                this.viewer.renderer.camera.poi[1] + .25,
                                this.viewer.renderer.camera.poi[2]
                            ];
                            this.viewer.renderer.camera.up = [0.0, 0.0, 1.0];
                            this.viewer.renderer.camera.computeMatrix();

                            this.fitCamera();
                            this.updateViewerCube();

                            this.setState(() => ({ transformer: mat4.clone(this.viewer.renderer.camera.transformer) }));
                        }}
                    >Sagittal</button>
                    <button className="viewButton"
                        onClick = {async () => {

                            this.viewer.renderer.camera.poi = [...this.center];
                            this.viewer.renderer.camera.eye = [
                                this.viewer.renderer.camera.poi[0] + .25,
                                this.viewer.renderer.camera.poi[1],
                                this.viewer.renderer.camera.poi[2]
                            ];
                            this.viewer.renderer.camera.up = [0.0, 0.0, 1.0];
                            this.viewer.renderer.camera.computeMatrix();

                            this.fitCamera();
                            this.updateViewerCube();

                            this.setState(() => ({ transformer: mat4.clone(this.viewer.renderer.camera.transformer) }));
                        }}
                    >Coronal</button>
                </div>

            </div>
        );
    }
}

export default ModelView;